import React, { Component } from "react";
import content from "../../../services/content";
import { updateFeed } from "../../../store/actions";
import SkeletonScreen from "../Common/SkeletonScreen";
import { connect } from "react-redux";
import Content from "./Content";
import { Row, Col } from "reactstrap";
import user from "../../../services/user";

const mapStateToProps = (state) => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFeed: (feeds) => {
      dispatch(
        updateFeed({
          feeds,
        })
      );
    },
  };
};

class Feeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      pageNo: 1,
      pageSize: 10,
      hasMore: false,
      selectedOption: ""
    };
  }
  getFeeds = () => {
    let feeds = [];
    let data = { page: this.state.pageNo, pageSize: this.state.pageSize };
    content.list(data).then((response) => {
      if (response.data.success) {
        feeds = response.data.contents;

        // Check if action is not null and filter based on user's country
        feeds = feeds.filter(feed => {
          if (feed.action !== null && feed.user !== null) {
  
            if (feed.action.country === feed.user.country) {
              return true
            }
          } else {
            return true; // If action is null, include the feed
          }
        });
        if (this.state.pageNo > 1) {
          feeds = [...this.props.feeds, ...feeds];
        }
        this.props.updateFeed(feeds);
        this.setState({ loader: false });
        if (
          response.data.contents.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState((prevState) => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1,
          }));
        } else {
          this.setState({ hasMore: false });
        }
      }
    });
  };

  componentDidMount() {
    // this.fetchData();
    this.getFeeds()
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  fetchData = () => {
    // Make API call to get the user's customization data
    user.getCustomizeData().then(response => {
      // Check if response contains data
      if (response && response.data.content_preference) {
        // Update state with fetched data
        console.log(response.content_preference, "kfdsjlkfj")
        this.setState({
          selectedOption: response.data.content_preference,
        });
        this.getFeeds();
      }
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
  };

  handleScroll = (e) => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getFeeds();
      }
    }
  };

  render() {

    return (
      <div className="loadMore">
        <Row className="mb-2">
          <Col md={12} lg={12} className="col-12">
            <h4 className="text-left status">What's Going On?</h4>
          </Col>
          {/* <Col md={4} lg={6} className="col-4 text-right px-0">
            <div className="btn-group mr-2">
              <button
                type="button"
                className="btn btn-light dropdown-toggle show-all-btn"
                data-toggle="dropdown"
              >
                Show All
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another actions
                </a>
              </div>
            </div>
          </Col> */}
        </Row>

        {this.state.loader ? (
          <SkeletonScreen />
        ) : (
          <React.Fragment>
            {this.props.feeds.map((feed, i) => (
              <Content
                feed={feed}
                key={`content-${i}`}
                handleEditContentPopup={this.toggleEditPost}
                feedIndex={i}
                enableEditDelete={true}
                allComments={0}
                getFeeds={this.getFeeds}
              />
            ))}
            {this.state.hasMore && (
              <div className="mt-3">
                <SkeletonScreen height={130} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
