import {
  LOGIN,
  LOGOUT,
  UPDATE_FEED,
  DELETE_FEED,
  GET_ABUSING_CONTENT,
  POST_ACCEPT,
  GET_ACTIVITY,
  GET_INVITE_CODE,
  POST_INVITE_CODE,
} from "./constants";

let apiUrl = "https://api.civ.works";
let baseUrl = "https://api.civ.works/";

if (document.URL.indexOf("social.civ.works") > 0) {
  apiUrl = "https://api.civ.works";
  baseUrl = "https://api.civ.works/";
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://api.civ.works";
    baseUrl = "https://api.civ.works/";
  }
} else if (document.URL.indexOf("mitiztechnologies") > 0) {
  apiUrl = "https://api.civ.works";
  baseUrl = "https://api.civ.works/";
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://api.civ.works";
    baseUrl = "https://api.civ.works/";
  }
} else if (
  document.URL.indexOf("8081") > 0 ||
  document.URL.indexOf("3000") > 0
) {
  apiUrl = "https://api.civ.works";
  baseUrl = "https://api.civ.works/";
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://api.civ.works";
    baseUrl = "https://api.civ.works/";
  }
}

let initialState = {
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  friendRequests: [],
  notifications: [],
  sideMenu: [],
};

if (localStorage.getItem("token")) {
  initialState = { ...initialState, token: localStorage.getItem("token") };
}
if (localStorage.getItem("userName")) {
  initialState = {
    ...initialState,
    userName: localStorage.getItem("userName"),
  };
}
if (localStorage.getItem("uName")) {
  initialState = {
    ...initialState,
    uName: localStorage.getItem("uName"),
  };
}
if (localStorage.getItem("userId")) {
  initialState = { ...initialState, userId: localStorage.getItem("userId") };
}
if (localStorage.getItem("userType")) {
  initialState = {
    ...initialState,
    userType: localStorage.getItem("userType"),
  };
}
if (localStorage.getItem("userImage")) {
  initialState = {
    ...initialState,
    userImage: localStorage.getItem("userImage"),
  };
}

if (localStorage.getItem("userImageThumb")) {
  initialState = {
    ...initialState,
    userImageThumb: localStorage.getItem("userImageThumb"),
  };
}

function rootReducer(state = initialState, action) {
  let payload = action.payload;
  let feeds;
  let abusingContentData;
  let activityContent;
  let get_inviteCode;
  let postCode;

  switch (action.type) {
    case LOGIN:
      return { ...state, ...payload };
    case LOGOUT:
      const initialState = {
        apiUrl: apiUrl,
        baseUrl: baseUrl,
      };
      return (state = initialState);

    case UPDATE_FEED:
      feeds = payload.feeds;
      return { ...state, feeds };

    case POST_INVITE_CODE:
      postCode = payload.postCode;
      return { ...state, postCode };

    case GET_INVITE_CODE:
      get_inviteCode = payload.get_inviteCode;
      return { ...state, get_inviteCode };

    case GET_ACTIVITY:
      activityContent = payload.activityContent;
      return { ...state, activityContent };

    case GET_ABUSING_CONTENT:
      abusingContentData = payload.abusingContentData;
      return { ...state, abusingContentData };

    case DELETE_FEED:
      feeds = payload.feeds.filter(
        (feed) => feed.id !== payload.deleted_content_id
      );
      return { ...state, feeds };

    case POST_ACCEPT:
      abusingContentData = payload.abusingContentData.filter(
        (feed) => feed.id !== payload.id
      );
      return { ...state, abusingContentData };

    case "LIKE":
      feeds = state.feeds;
      let feed_index = feeds.findIndex((f) => f.id === payload.content_id);
      if (payload.total_likes === 0) {
        feeds[feed_index].likes = [];
      } else {
        feeds[feed_index].likes = payload.likes;
      }
      feeds[feed_index].total_likes = payload.total_likes;
      return { ...state, feeds };
    case "SAVE_FRIEND_REQUESTS":
      let friendRequests = payload.friendRequests;
      return { ...state, friendRequests };
    case "UPDATE_TOTAL_COUNTS":
      let totalCounts = payload.totalCounts;
      return { ...state, ...totalCounts };
    case "UPDATE_NOTIFICATION":
      let notifications = payload.notifications;
      return { ...state, notifications };

    case "SET_SIDE_MENU":
      return { ...state, ...payload };

    default:
      return state;
  }
}
export default rootReducer;
