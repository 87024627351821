import React, { Component } from "react";
class StripePricingModal extends Component {
    constructor(props) {
        super(props);
        this.pricingTableContainer = React.createRef();
    }
    componentDidMount() {
        this.loadStripePricingTableScript();
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.loadStripePricingTableScript();
        }
    }
    loadStripePricingTableScript = () => {
        if (this.pricingTableContainer.current) {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/pricing-table.js";
            script.async = true;
            this.pricingTableContainer.current.appendChild(script);
        }
    };
    render() {
        const { show, onClose, pricingTableId, publishableKey, customerEmail } = this.props;
        if (!show) {
            return null;
        }
        return (
            <div className="modal-overlay">
                <div className="modal-content custom-pricing-modal">
                    <div className="close-icon" onClick={onClose}>
                        &times;
                    </div>
                    <div ref={this.pricingTableContainer}>
                        <stripe-pricing-table
                            pricing-table-id="prctbl_1PThBDK8XwLluSS668tsZ7dk"
                            publishable-key="pk_live_UnAfWLLpWcCFjekpBOlxwzMl"
                            customer-email={customerEmail}
                        ></stripe-pricing-table>
                    </div>
                </div>
            </div>
        );
    }
}
export default StripePricingModal;