import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';

import Logout from "./views/Logout";
import FrontLayout from "./containers/DefaultLayout/FrontLayout";
import Login from "./views/Front/Login/Login";
import Signup from "./views/Front/Singup/Signup";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ForgotPassword from "./views/Front/Login/ForgotPassword";
import ResetPassword from "./views/Front/Login/ResetPassword";
import Page from "./views/Front/Page";
import FeedDetails from "./views/Front/FeedDetails";

import {
  faAsterisk,
  faTachometerAlt,
  faUserFriends,
  faCommentDots,
  faBell,
  faLink,
  faCamera,
  faPaperclip,
  faMapMarkerAlt,
  faTag,
  faCheckCircle,
  faThumbsUp,
  faUsers,
  faNewspaper,
  faChartBar,
  faCheck,
  faUserPlus,
  faSearch,
  faUser,
  faSignOutAlt,
  faForward,
  faFrown,
  faEnvelopeOpen,
  faUserCircle,
  faTimes,
  faList,
  faEdit,
  faTrash,
  faSpinner,
  faImage,
  faEllipsisH,
  faMapMarker,
  faPlus,
  faCheckSquare,
  faGlobe,
  faBlog,
  faUndo,
  faBullhorn,
  faBookOpen,
  faShare,
  faReply,
  faArrowLeft,
  faLock,
  faCloudUploadAlt,
  faPencilAlt,
  faExclamation,
  faMinus,
  faPoll,
  faBookmark,
  faEllipsisV,
  faPaperPlane,
  faFilePdf,
  faCircle,
  faCrop,
  faTasks,
  faRetweet,
  faRss,
  faSync,
  faUserCog,
  faPhoneAlt,
  faBan
} from "@fortawesome/free-solid-svg-icons";
import WellKnown from "./views/Front/Wellknown";

library.add(
  fab,
  faAsterisk,
  faTachometerAlt,
  faUserFriends,
  faCommentDots,
  faBell,
  faLink,
  faCamera,
  faPaperclip,
  faMapMarkerAlt,
  faTag,
  faCheckCircle,
  faThumbsUp,
  faUsers,
  faNewspaper,
  faChartBar,
  faCheck,
  faUserPlus,
  faSearch,
  faUser,
  faSignOutAlt,
  faForward,
  faFrown,
  faEnvelopeOpen,
  faUserCircle,
  faTimes,
  faList,
  faEdit,
  faTrash,
  faSpinner,
  faImage,
  faEllipsisH,
  faMapMarker,
  faPlus,
  faCheckSquare,
  faGlobe,
  faBlog,
  faUndo,
  faBullhorn,
  faBookOpen,
  faShare,
  faReply,
  faArrowLeft,
  faLock,
  faCloudUploadAlt,
  faPencilAlt,
  faExclamation,
  faMinus,
  faPoll,
  faBookmark,
  faEllipsisV,
  faPaperPlane,
  faFilePdf,
  faCircle,
  faCrop,
  faTasks,
  faRetweet,
  faRss,
  faSync,
  faUserCog,
  faPhoneAlt,
  faBan
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const AdminLogin = React.lazy(() => import("./views/Admin/Login/Login"));
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/admin/login"
              name="Login Page"
              render={props => <AdminLogin {...props} />}
            />
            <Route
              exact
              path="/admin/logout"
              name="Logout Page"
              render={props => <Logout {...props} />}
            />
            <Route
              exact
              path="/logout"
              name="Logout Page"
              render={props => <Logout {...props} />}
            />
            <Route
              path="/admin"
              name="Admin"
              render={props => <DefaultLayout {...props} />}
            />
            <Route
              path="/login"
              name="Login"
              render={props => <Login {...props} />}
            />
            <Route
              path="/confirm-email/:code"
              name="Confirm Email"
              render={props => <Login {...props} />}
            />
            <Route
              path="/confirm-admin-email/:code"
              name="Confirm Email"
              render={props => <Login {...props} />}
            />
            <Route
              path="/forgot-password"
              name="Forgot Password"
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              path="/reset-password/:token"
              name="Reset Password"
              render={props => <ResetPassword {...props} />}
            />
            <Route
              path="/signup"
              name="Signup"
              render={props => <Signup {...props} />}
            />
            <Route
              path="/signup-with-invitation/:code"
              name="Signup"
              render={props => <Signup {...props} />}
            />
            <Route
              path="/terms-and-condition"
              name="Terms and condition"
              render={props => <Page {...props} />}
            />
            <Route
              path="/privacy-policy"
              name="Privacy Policy"
              render={props => <Page {...props} />}
            />
            <Route
              path="/feed/:id/details"
              name="Feed Details"
              render={props => <FeedDetails {...props} />}
            />
            <Route
              path="/.well-known/did.json"
              name="FrontLayout"
              render={props => <WellKnown {...props} />}
            />
            <Route
              path="/"
              name="FrontLayout"
              render={props => <FrontLayout {...props} />}
            />
           
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
