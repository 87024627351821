import React, { Component } from "react";


class WellKnown extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    return (
      <React.Fragment>
        {JSON.stringify({
  "@context": ["https://www.w3.org/ns/did/v1"],
  "id": "did:web:your-domain.com",
  "service": [
    {
      "id": "#feed",
      "type": "AtprotoFeedGenerator",
      "serviceEndpoint": "https://your-domain.com"
    }
  ]
})}
     </React.Fragment>
    );
  }
}

export default WellKnown;
